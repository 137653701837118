import React from 'react';
import PropTypes from 'prop-types';
import {useTheme, makeStyles} from '@material-ui/core/styles';
import {useMediaQuery, Grid, Typography, Box, Divider, Paper} from '@material-ui/core';
import {Button, Link} from 'gatsby-theme-material-ui';
import {Video, Image} from 'components/atoms';
import {SectionHeader} from 'components/molecules';
import { HeroSideImage } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  heroImage: {
    // marginLeft: '-1.1%', 
    width: '100%',
    // border:'2px solid #fff',
    // padding:'1px',
    // boxShadow: '-5px -5px',
    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',

    boxShadow: 'rgba(50, 50, 50, 0.18) 0px 8px 24px',
    //boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 4px 0px inset',

    // boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
    // margin:'3px'
  },

}));


const Hero = props => {
  const {className, ...rest} = props;

  const classes = useStyles();

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true,
  });
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
        <Typography variant={isSm ? "h3" : "h4"} align="left" color="textPrimary" gutterBottom>
        <Box fontWeight='bold' mt={7} mb={0}>
          Hi, I'm Ashu
        </Box>
        </Typography>
        <Typography variant={isMd ? "h5" : "h6"} align="left" color="textPrimary" gutterBottom>
        <Box mb={isMd? 5 : 3}>
          I coach founders of impact-oriented startups and nonprofits
        </Box>
        </Typography>
        
        <Image src='https://res.cloudinary.com/studiocollective/image/upload/v1677304397/Coaching%20Site/DSC04864_2_qkmb0m_73385d_f45e9a.jpg'  className={classes.heroImage}/>
        <Typography component="div" variant="body1" gutterBottom>
        <Box component="p" mt={isMd? 5 : 4} mb={3}>
          <b>I spent a decade building <Link to="/makeschool" color="inherit">Make School</Link></b> - a venture funded university that reached $10m annual revenue and served as a talent pipeline for high growth tech companies. My work centered around organizational design, systems building, and coaching our exec team.
        </Box>
        <Box component="p" mt={1} mb={3}>
          <b>I then spent a year as a visiting partner at Y Combinator</b> studying what makes founders and organizations great across industries and geographies. 
        </Box>
        <Box component="p" mt={1} mb={3}>
          I'm now coaching a tightly curated group of founders seeking to build impact-oriented, resilient organizations. I love working with leaders from all backgrounds who are systems minded and strive for excellence.
        </Box>
        <Box component="p" mt={1} mb={3}>
          If you're interested, you can reach me at <a href="mailto:hi@ashu.xyz">hi@ashu.xyz</a>
        </Box>
        <Box component="p" mt={1} mb={3}>
          <b>Testimonials</b> 
        </Box>
        <Box mt={1} mb={3}>
          <Paper elevation={0} square style={{borderLeftStyle: 'inset', paddingLeft: 20}} > 
          <i>“Ashu's communication/pitch feedback helped us expediently close our Series C. He’s among the sharpest thinkers in the YC community.”</i>
          </Paper>
        </Box>
        <Box mt={1} mb={3}>
          <Paper elevation={0} square style={{borderLeftStyle: 'inset', paddingLeft: 20}} > 
          <i>“It's the perfect balance between someone who's been through the journey and just 'gets it' while also seen enough to understand nuances.”</i>
          </Paper>
        </Box>
        <Box mt={1} mb={3}>
          <Paper elevation={0} square style={{borderLeftStyle: 'inset', paddingLeft: 20}} > 
          <i>“His long term perspective and keen awareness of my habits have pushed me to operate more consistently. I've come to trust him as much as I would a cofounder.”</i>
          </Paper>
        </Box>
       
      </Typography>
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;


