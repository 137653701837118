import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Divider} from '@material-ui/core';
import {Section} from 'components/organisms';
import {Hero} from './components';
import {SEO} from 'components/atoms';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  section: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(8),
    },
  }
}));

const pageContext = {
  frontmatter: {
      title: "Ashu Desai | Founder Coach",
  }
}

const Landing = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SEO pageContext={pageContext} />
      <Section className={classes.section} narrow>
        <Hero />
      </Section>
    </div>
  );
};

export default Landing;
